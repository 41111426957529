import React from 'react'
import { decodeEntities } from '../../utils/htmlParse'
import { getIconSrc } from '../../utils/getIconSrc'
import './InsightBoxes.scss'

export const InsightBoxes = props => {
  const {
    qualifiedBoxBigText,
    qualifiedBoxSubText,
    tradeCertifiedBoxBigText,
    tradeCertifiedBoxSubText,
    staffBoxBigText,
    staffBoxSubText,
    leftInsightBoxHeading,
    leftInsightBoxInsights,
    rightInsightBoxHeading,
    rightInsightBoxInsights,
  } = props

  return (
    <section className="insight-boxes">
      <div className="wrapper">
        <div className="top-boxes">
          <div className="insight-box qualified">
            <img
              className="insight-icon"
              src={getIconSrc('qualified')}
              alt="insight icon"
            />
            <span className="big-text">
              {decodeEntities(qualifiedBoxBigText)}
            </span>
            <span className="sub-text">
              {decodeEntities(qualifiedBoxSubText)}
            </span>
          </div>
          <div className="insight-box trade-certified">
            <img
              className="insight-icon"
              src={getIconSrc('trade')}
              alt="insight icon"
            />
            <span className="big-text">
              {decodeEntities(tradeCertifiedBoxBigText)}
            </span>
            <span className="sub-text">
              {decodeEntities(tradeCertifiedBoxSubText)}
            </span>
          </div>
          <div className="insight-box staff">
            <img
              className="insight-icon"
              src={getIconSrc('staff')}
              alt="insight icon"
            />
            <span className="big-text">{decodeEntities(staffBoxBigText)}</span>
            <span className="sub-text">{decodeEntities(staffBoxSubText)}</span>
          </div>
        </div>
        <div className="bottom-boxes">
          {leftInsightBoxHeading ? (
            <div className="insight-box list-left">
              <span className="insight-list-heading">
                {decodeEntities(leftInsightBoxHeading)}
              </span>
              {leftInsightBoxInsights && (
                <ul className="insight-list">
                  {leftInsightBoxInsights.map((insight, index) => {
                    const { insightTitle, insightText } = insight
                    return (
                      <li key={index} className="insight-list-item">
                        {insightTitle && (
                          <span className="insight-list-item-title">
                            {decodeEntities(insightTitle)}
                          </span>
                        )}
                        <span className="insight-list-item-text">
                          {decodeEntities(insightText)}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          ) : (
            ''
          )}
          {rightInsightBoxHeading ? (
            <div className="insight-box list-right">
              <span className="insight-list-heading">
                {decodeEntities(rightInsightBoxHeading)}
              </span>
              {leftInsightBoxInsights && (
                <ul className="insight-list">
                  {rightInsightBoxInsights.map((insight, index) => {
                    const { insightTitle, insightText } = insight
                    return (
                      <li key={index} className="insight-list-item">
                        {insightTitle && (
                          <span className="insight-list-item-title">
                            {decodeEntities(insightTitle)}
                          </span>
                        )}
                        <span className="insight-list-item-text">
                          {decodeEntities(insightText)}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              )}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </section>
  )
}
