import React from 'react';
import AcfLink from '../../components/AcfLink';
import { decodeEntities } from '../../utils/htmlParse';
import './CompanyLinks.scss';

export const CompanyLinks = ({ heading, rows }) => {
  if (!rows) return <div>No company links exist. Add in WordPress</div>;
  return (
    <section className="company-links">
      <div className="wrapper">
        <h2 className="company-links-heading">
          {decodeEntities(heading)}
        </h2>
        <div className="company-links-container">
          {rows.map((row, index) => {
            const { logo, description, buttonLink } = row;
            return (
              <div key={index} className="company-link">
                <span className={`company-logo ${logo}`} />
                <div
                  className="company-description"
                  dangerouslySetInnerHTML={{__html: description}}
                />
                {buttonLink && (
                  <div className="button-container">
                    <AcfLink className="button" {...buttonLink} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
