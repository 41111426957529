import React, { useRef, useEffect, useState } from 'react';
import { Carousel } from '../../components/Carousel';
import Image from '../../components/Image';
import AcfLink from '../../components/AcfLink';
import { decodeEntities } from '../../utils/htmlParse';
import './ImageAndTextSlider.scss';

export const ImageAndTextSlider = ({heading, slides}) => {
  const [maxSlideHeight, setMaxSlideHeight] = useState('auto');
  const slider = useRef(null);
  useEffect(() => {
    // Wait 1 second before getting max height value, allow
    // slider element to load in the DOM
    setTimeout(() => {
      if (slider && slider.current) {
        const slideHeights =
          Array.from(slider.current.querySelectorAll('.image-and-text-slider-slide-text'))
          .map(node => node.clientHeight);
        const maxHeight = Math.max(...slideHeights);
        setMaxSlideHeight(maxHeight);
      }
    }, 1000)
  }, [])
  const handleOnDragStart = e => e.preventDefault();
  return (
    <section className="image-and-text-slider">
      <div className="wrapper">
        <h2 className="image-and-text-slider-heading">
          {decodeEntities(heading)}
        </h2>
        {slides && (
          <div className="image-and-text-slider-slides" ref={slider}>
            <Carousel>
              {slides.map(slide => {
                const {
                  image,
                  title,
                  smallTitle,
                  content,
                  buttonLink
                } = slide;
                return (
                  <div className="image-and-text-slider-slide" onDragStart={handleOnDragStart} key={title}>
                    <div className="image-and-text-slider-slide-image">
                      <div className="image">
                        <Image image={image} />
                      </div>
                    </div>
                    <div className="image-and-text-slider-slide-text" style={{height: maxSlideHeight}}>
                      <span className="image-and-text-slider-slide-small-title">
                        {decodeEntities(smallTitle)}
                      </span>
                      <h3 className="image-and-text-slider-slide-title">
                        {decodeEntities(title)}
                      </h3>
                      <div
                        className="image-and-text-slider-slide-content"
                        dangerouslySetInnerHTML={{__html: content}}
                      />
                      {buttonLink && <AcfLink {...buttonLink} className="button" />}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        )}
      </div>
    </section>
  );
};
