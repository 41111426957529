import React from 'react';
import { decodeEntities } from '../../utils/htmlParse';
import AcfLink from '../../components/AcfLink';
import './FullWidthCta.scss';

export const FullWidthCta = (props) => {
  const {
    heading,
    content,
    buttonLink
  } = props;
  return (
    <section className="full-width-cta">
      <div className="wrapper">
        <span className="full-width-cta-heading">{decodeEntities(heading)}</span>
        <div className="flex-wrap">
          <h2 className="full-width-cta-content">{decodeEntities(content)}</h2>
          {buttonLink && (
            <div className="button-container">
              <AcfLink className="button" {...buttonLink} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
