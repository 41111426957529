import React from 'react';
import { decodeEntities } from '../../utils/htmlParse';
import './PageOverview.scss';

export const PageOverview = ({ heading, content }) => {
  return (
    <section className="page-overview">
      <div className="wrapper">
        <h2 className="page-overview-heading">{decodeEntities(heading)}</h2>
        <div
          className="page-overview-content"
          dangerouslySetInnerHTML={{__html: content}}
        />
      </div>
    </section>
  );
};
