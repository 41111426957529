import React from 'react';
import './SimpleListBlock.scss';

export const SimpleListBlock = (props) => {
  const {
    listItems,
  } = props;
  return (
    <section className="simple-list-block">
      <div className="wrapper">
        <ul>
          {listItems.map(item => {
            return (
              <li key={item.listItem}>{item.listItem}</li>
            );
          })}
        </ul>
      </div>
    </section>
  );
};
