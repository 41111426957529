import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
import './Breadcrumbs.scss';

const Breadcrumbs = ({ crumbs, customCrumbs }) => {
  const { allWordpressPage } = useStaticQuery(graphql`
    query {
      allWordpressPage {
        nodes {
          title
          slug
          link
        }
      }
    }
  `);
  return (
    <div className="breadcrumbs">
      {customCrumbs && customCrumbs.map(crumb => (
        <div className="crumb" key={crumb.link}>
          <Link to={crumb.link}>
            {decodeEntities(crumb.title)}
          </Link>
        </div>
      ))}
      {!customCrumbs && crumbs && allWordpressPage && crumbs.map((crumb, index) => {
        const { pathname, crumbLabel } = crumb;
        // const pageData = allWordpressPage.nodes.find(page => {
        //   const customPathName = pathname !== '/' ? `${pathname}/` : pathname;
        //   return customPathName === page.link;
        // });
        return (
          <div className="crumb" key={index}>
            <Link to={`${pathname}/`}>
              {decodeEntities(crumbLabel.replace(/-/g, ' '))}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
