import React, { useRef, useEffect, useState } from 'react'
import { decodeEntities } from '../../utils/htmlParse'
import { Carousel } from '../../components/Carousel'
import AcfLink from '../../components/AcfLink'
import { getIconSrc } from '../../utils/getIconSrc'
import './TextAndSlider.scss'

export const TextAndSlider = props => {
  const [maxSlideHeight, setMaxSlideHeight] = useState('auto')
  const slider = useRef(null)
  useEffect(() => {
    // Wait 1 second before getting max height value, allow
    // slider element to load in the DOM
    setTimeout(() => {
      if (slider && slider.current) {
        const slideHeights = Array.from(
          slider.current.querySelectorAll('.text-and-slider-slider-slide')
        ).map(node => node.clientHeight)
        const maxHeight = Math.max(...slideHeights)
        setMaxSlideHeight(maxHeight)
      }
    }, 1000)
  }, [])
  const { heading, content, buttonLink, slides } = props
  return (
    <section className="text-and-slider">
      <div className="text-and-slider-text">
        <h2 className="text-and-slider-text-heading">
          {decodeEntities(heading)}
        </h2>
        <div
          className="text-and-slider-text-content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
        {buttonLink && <AcfLink className="button" {...buttonLink} />}
      </div>
      <div className="text-and-slider-slider" ref={slider}>
        <Carousel
          responsive={{
            0: {
              items: 1,
            },
            800: {
              items: 2,
            },
          }}
          stagePadding={{
            paddingRight: 80,
          }}
        >
          {slides.map(slide => (
            <div
              className="text-and-slider-slider-slide"
              key={slide.title}
              style={{ height: maxSlideHeight }}
            >
              {slide.icon && getIconSrc(slide.icon) && (
                <img
                  src={getIconSrc(slide.icon)}
                  alt="Slide icon"
                  className="text-and-slider-slider-slide-icon"
                />
              )}
              <h3 className="text-and-slider-slider-slide-title">
                {decodeEntities(slide.title)}
              </h3>
              <p className="text-and-slider-slider-slide-content">
                {decodeEntities(slide.content)}
              </p>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  )
}
