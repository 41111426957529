import React, { useState, useEffect } from 'react';
import OnVisible from 'react-on-visible';
import { Link as ScrollLink, Element } from 'react-scroll';
import { decodeEntities } from '../../utils/htmlParse';
import Image from '../../components/Image';
import AcfLink from '../../components/AcfLink';
import './RepeatableImageAndText.scss';

export const RepeatableImageAndText = ({ disableTabs, sections }) => {
  const [openSections, setOpenSections] = useState([0]);

  const toggleOpenSections = (idx) => {
    const openSectionsIndex = openSections.indexOf(idx);
    const openSectionsClone = [...openSections];
    if (openSectionsIndex === -1) {
      openSectionsClone.push(idx);
    } else {
      openSectionsClone.splice(openSectionsIndex, 1);
    }
    setOpenSections(openSectionsClone);
  }

  if (!sections) return <div>No RepeatableImageAndText sections found. Add sections in WordPress</div>
  const sectionNavItems = sections.map(section => section.heading);
  return (
    <section className={`repeatable-image-and-text ${disableTabs ? 'no-tabs' : ''}`}>
      {!disableTabs && (
        <div className="sections-nav">
          <div className="wrapper">
            {sectionNavItems.map((item, sectionIndex) => (
              <ScrollLink
                to={`repeatable-section-${sectionIndex}`}
                spy
                smooth="true"
                className="sections-nav-item"
                key={item}
              >
                {decodeEntities(item)}
              </ScrollLink>
            ))}
          </div>
        </div>
      )}
      <div className="wrapper">
        <div className="image-and-text-sections">
          {sections.map((section, index) => {
            const {
              image,
              smallHeading,
              heading,
              icon,
              content,
              buttonLink,
            } = section;
            return (
              <OnVisible key={index} wrappingElement="div" percent={60} className={`repeatable-section-container ${openSections.indexOf(index) >= 0 ? 'open' : ''}`}>
                {!disableTabs && (
                  <button
                    type="button"
                    className="mobile-accordion-toggle"
                    onClick={() => toggleOpenSections(index)}
                  >
                    {decodeEntities(heading)}
                  </button>
                )}
                {/* eslint-disable-next-line */}
                <Element
                  name={`repeatable-section-${index}`}
                  className="image-and-text-section"
                >
                  <div className="image-and-text-section-wrapper">
                    <div className="image-container">
                      <div className="image">
                        <Image image={image} />
                      </div>
                    </div>
                    <div className="text-container">
                      {smallHeading && (
                        <span className="text-container-small-heading">
                          {decodeEntities(smallHeading)}
                        </span>
                      )}
                      <div className="heading-container">
                        {icon && (
                          <span className={`section-icon ${icon}`} />
                        )}
                        {heading && (
                          <h2 className="text-container-heading">
                            {decodeEntities(heading)}
                          </h2>
                        )}
                      </div>
                      {content && (
                        <div
                          className="text-container-content"
                          dangerouslySetInnerHTML={{__html: content}}
                        />
                      )}
                      {buttonLink && <AcfLink {...buttonLink} className="button" />}
                    </div>
                  </div>
                </Element>
              </OnVisible>
            );
          })}
        </div>
      </div>
    </section>
  );
};
