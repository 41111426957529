import React from 'react';
import GoogleMapReact from 'google-map-react';
import { useStaticQuery, graphql } from 'gatsby';
import { decodeEntities } from '../../utils/htmlParse';
import './OfficeLocations.scss';

// Google maps native options override
// https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
const createMapOptions = (maps) => {
  return {
    disableDefaultUI: true,
    scrollwheel: false,
    styles: [{
      stylers: [{
        saturation: -100
      }]
    }]
  }
};
const createMapStyles = () => {
  return {
    stylers: [{
      saturation: -100
    }]
  }
};

export const OfficeLocations = () => {
  const { wordpressAcfOptions: { options } } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          googleMapsApiKey
          locations {
            locationName
            googleMap {
              lat
              lng
            }
            addressDisplay
            addressPostal
            email
            phone
          }
        }
      }
    }
  `);
  const { googleMapsApiKey = null } = options;

  // If there are no locations to display return null
  if (
    googleMapsApiKey === null ||
    typeof options.locations === 'undefined' ||
    options.locations.length === 0
  ) {
    return null;
  }

  return (
    <section className="office-locations">
      <div className="wrapper">
        {options.locations && options.locations.map(loc => {
          const {
            locationName,
            googleMap,
            addressDisplay,
            addressPostal,
            email,
            phone
          } = loc;
          return (
            <div className="office-location" key={locationName}>
              <h2 className="office-location-name">{`HIFraser ${decodeEntities(locationName)}`}</h2>
              <div className="office-location-details">
                {(addressDisplay || addressPostal) && (
                  <div className="address-details">
                    {addressDisplay && <span className="address">{decodeEntities(addressDisplay)}</span>}
                    {addressPostal && <span className="address-postal">{decodeEntities(addressPostal)}</span>}
                  </div>
                )}
                {phone && <a className="phone" href={`tel:${phone}`}>{decodeEntities(phone)}</a>}
                {email && <a className="email" href={`mailto:${email}`}>{decodeEntities(email)}</a>}
              </div>
              <div className="map">
                <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapsApiKey }}
                  defaultCenter={{
                    lat: parseFloat(googleMap.lat),
                    lng: parseFloat(googleMap.lng)
                  }}
                  defaultZoom={15}
                  options={createMapOptions}
                  styles={createMapStyles}
                >
                  <div
                    className="map-marker"
                    lat={googleMap.lat}
                    lng={googleMap.lng}
                  />
                </GoogleMapReact>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};
