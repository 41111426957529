import React from 'react';
import { decodeEntities } from '../../utils/htmlParse';
import AcfLink from '../../components/AcfLink';
import './CtaBlock.scss';

export const CtaBlock = ({ ctas }) => {
  if (ctas.length === 0) return null;
  return (
    <section className="cta-block">
      <div className="wrapper">
        {ctas.map(cta => {
          const { heading, content, buttonLink } = cta;
          return (
            <div className="cta" key={heading}>
              <span className="cta-heading">{decodeEntities(heading)}</span>
              <h2 className="cta-content">{decodeEntities(content)}</h2>
              {buttonLink && <AcfLink className="button" {...buttonLink} />}
            </div>
          );
        })}
      </div>
    </section>
  );
};
