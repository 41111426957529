import React from 'react'
import { graphql } from 'gatsby'
import Previewable from '../components/Previewable'
import Layout from '../components/Layout'
import * as AcfLayout from '../acf'
import SEO from '../components/SEO'
import { decodeEntities } from '../utils/htmlParse'
import { getUrlVars } from '../utils/helpers'

const AcfComponent = ({
  location,
  componentName,
  item,
  pageContext,
  pageTitle,
  previewing,
}) => {
  // If component does not exist in acf folder, print error message
  const ComponentName = AcfLayout[componentName]
  if (!ComponentName) {
    return (
      <div className="wrapper">
        {`Error: Component does not exist. `}
        {`Please create component "${componentName}.jsx" in src/acf folder `}
        {`and add export to src/acf/index.js`}
      </div>
    )
  }
  return (
    <ComponentName
      location={location}
      pageContext={pageContext}
      pageTitle={pageTitle}
      previewing={previewing}
      {...item}
    />
  )
}

const Page = ({ data, location, pageContext, previewing = false }) => {
  const { wordpressPost: page, site } = data
  if (!page) return null
  const { title, slug, yoast, acf = {} } = page
  const { layout } = acf
  const { title: siteTitle } = site.siteMetadata
  getUrlVars()
  return (
    <Layout
      location={location}
      className={slug === 'product' ? 'product-page-template' : ''}
    >
      <SEO
        title={`${
          yoast.metaTitle
            ? decodeEntities(yoast.metaTitle)
            : `${decodeEntities(title)} | ${decodeEntities(siteTitle)}`
        }`}
        desc={yoast.metaDescription}
      />
      {layout && layout.length > 0 ? (
        layout.map(item => {
          if (!item.__typename) return null
          const layoutComponentName = item.__typename.replace(
            'WordPressAcf_',
            ''
          )
          return (
            <AcfComponent
              pageTitle={title}
              key={item.id}
              componentName={layoutComponentName}
              pageContext={pageContext}
              item={item}
              location={location}
              previewing={previewing}
            />
          )
        })
      ) : (
        <div className="no-content" style={{ padding: '80px 0px 100px' }}>
          <div className="wrapper">
            <span style={{ fontWeight: '700' }}>
              Sorry, there&apos;s no content here yet...
            </span>
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Previewable(Page, 'page')

export const pageQuery = graphql`
  query PageById($id: String!) {
    site {
      siteMetadata {
        title
        functionsUrl
      }
    }
    wordpressPost: wordpressPage(id: { eq: $id }) {
      title
      content
      slug
      wordpress_id
      yoast {
        metaTitle: title
        metaDescription: metadesc
      }
      acf {
        layout: layout_page {
          __typename
          ...BannerQuery
          ...TextAndSliderQuery
          ...ProductSearchQuery
          ...ImageAndTextSliderQuery
          ...RecentCaseStudiesQuery
          ...RecentNewsQuery
          ...PageOverviewQuery
          ...RepeatableImageAndTextQuery
          ...InsightBoxesQuery
          ...ListBlockQuery
          ...SimpleListBlockQuery
          ...CtaBlockQuery
          ...FullWidthCtaQuery
          ...IndustriesGridQuery
          ...CompanyLinksQuery
          ...TeamMembersQuery
          ...OfficeLocationsQuery
          ...GenericContentQuery
          ...TextHeaderQuery
          ...FormBlockQuery
          ...HomepageBannerQuery
          ...CardLayoutQuery
        }
      }
    }
  }
`
