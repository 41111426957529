import React from 'react';
import OnVisible from 'react-on-visible';
import Image from '../../components/Image';
import Breadcrumbs from '../../components/Breadcrumbs';
import AcfLink from '../../components/AcfLink';
import './Banner.scss';

export const Banner = (props) => {
  const {
    smallHeading,
    heading,
    content,
    image,
    buttonLink,
    pageContext = {},
    location,
    pageTitle,
    customCrumbs = null,
    disableBreadcrumbs = false,
    previewing
  } = props;
  const { breadcrumb = {} } = pageContext;
  const { crumbs = null } = breadcrumb;
  return (
    <>
      <OnVisible wrappingElement="section" className="banner">
        <div className="wrapper">
          <div className="banner-text">
            <span
              className="banner-small-heading"
              dangerouslySetInnerHTML={{__html: smallHeading}}
            />
            <h1
              className="banner-heading"
              dangerouslySetInnerHTML={{__html: heading}}
            />
            <p className="banner-content">{content}</p>
            {buttonLink && <AcfLink className="button" {...buttonLink} />}
          </div>
        </div>
        <div className="banner-image">
          <Image image={image} />
        </div>
      </OnVisible>
      {location && location.pathname !== '/' && !previewing && !disableBreadcrumbs && (
        <div className="breadcrumbs-container">
          <div className="wrapper">
            <Breadcrumbs
              customCrumbs={customCrumbs}
              crumbs={crumbs}
              crumbLabel={pageTitle}
            />
          </div>
        </div>
      )}
    </>
  );
}
