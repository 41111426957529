import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities, getExcerpt } from '../../utils/htmlParse';
import RelatedItemsBlock from '../../components/RelatedItemsBlock';
import './RecentNews.scss';

export const RecentNews = ({ heading }) => {
  const { allWordpressPost, site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          blogSlug
        }
      }
      allWordpressPost(
        sort: {fields: date, order: DESC},
        filter: { slug: { ne: "gatsby-build-post" } }
        limit: 3
      ) {
        nodes {
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 390, quality: 90) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          content
          link
        }
      }
    }
  `);
  if(!allWordpressPost || allWordpressPost.nodes.length === 0) return null;
  return (
    <RelatedItemsBlock
      heading={decodeEntities(heading)}
      viewMoreText="View all news"
      viewMoreLink={`/${site.siteMetadata.blogSlug}/`}
      items={allWordpressPost.nodes.map(post => ({...post, excerpt: getExcerpt(post.content, 100)}))}
    />
  );
};
