import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { decodeEntities } from '../../utils/htmlParse';
import './IndustriesGrid.scss';

export const IndustriesGrid = () => {
  // 205 is the WordPress page id for Industries page
  const { allWordpressPage: industries } = useStaticQuery(graphql`
    query {
      allWordpressPage(filter: {wordpress_parent: {eq: 205}}) {
        nodes {
          title
          slug
        }
      }
    }
  `);

  const [reversed, isReversed] = useState(false);

  useEffect(() => {
    isReversed(industries.nodes.reverse());
  },[])

  return (
    reversed && (
      <section className="industries-grid">
        <div className="wrapper">
          <div className="industries">
          
            {Object.keys(reversed).length > 0 &&
              reversed?.map((industry) => {
                return (
                  <Link
                    className={`industry ${industry.slug}`}
                    to={`/industries/${industry.slug}/`}
                    key={industry.slug}
                  >
                    <span className="industry-name">
                      {decodeEntities(industry.title)}
                    </span>
                  </Link>
                );
              })}
          </div>
        </div>
      </section>
    )
    
  );
};
