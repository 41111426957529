import React from 'react';
import { decodeEntities } from '../../utils/htmlParse';
import Image from '../../components/Image';
import './TeamMembers.scss';

export const TeamMembers = (props) => {
  const {
    heading,
    smallHeading,
    intro,
    teamMembers
  } = props;
  return (
    <section className="team-members">
      <div className="team-members-header">
        <div className="wrapper">
          <span>{decodeEntities(smallHeading)}</span>
          <h1 className="team-members-heading">{decodeEntities(heading)}</h1>
          <div
            className="header-content"
            dangerouslySetInnerHTML={{__html: intro}}
          />
        </div>
      </div>
      <div className="team-members-grid">
        <div className="wrapper">
          {teamMembers && teamMembers.map((teamMember, index) => {
            const { photo, fullName, role, socialLinks } = teamMember;
            return (
              <div className="team-member" key={index}>
                <div className="team-member-photo">
                  <Image image={photo} />
                </div>
                <span className="team-member-name">
                  {decodeEntities(fullName)}
                </span>
                <span className="team-member-role">
                  {decodeEntities(role)}
                </span>
                {socialLinks && (
                  <div className="social-links">
                    {socialLinks.map((socialLink, index) => {
                      const { linkText, linkUrl } = socialLink;
                      return (
                        <a key={index} className="social-link" href={linkUrl}>
                          {decodeEntities(linkText)}
                        </a>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
