import React, { useRef, useEffect, useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Image from '../../components/Image'
import { Carousel } from '../../components/Carousel'
import { decodeEntities, getExcerpt } from '../../utils/htmlParse'
import './RecentCaseStudies.scss'

export const RecentCaseStudies = ({
  heading,
  isManuallySelectedCaseStudy = false,
  manualCaseStudies = {},
}) => {
  const [maxSlideHeight, setMaxSlideHeight] = useState('auto')
  const slider = useRef(null)
  useEffect(() => {
    // Wait 1 second before getting max height value, allow
    // slider element to load in the DOM
    setTimeout(() => {
      if (slider && slider.current) {
        const slideHeights = Array.from(
          slider.current.querySelectorAll('.recent-case-study-text')
        ).map(node => node.clientHeight)
        const maxHeight = Math.max(...slideHeights)
        setMaxSlideHeight(maxHeight)
      }
    }, 1000)
  }, [])
  const handleOnDragStart = e => e.preventDefault()

  const { allWordpressWpCaseStudy } = useStaticQuery(graphql`
    query {
      allWordpressWpCaseStudy(
        sort: { fields: date, order: DESC }
        filter: { slug: { ne: "gatsby-build-case-study" } }
      ) {
        nodes {
          title
          content
          slug
          link
          wordpress_id
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)

  const [caseStudies, setCaseStudies] = useState(allWordpressWpCaseStudy?.nodes)

  /**
   * Allow for filtering through the manual case studies if this option is toggled via ACF
   */
  useEffect(() => {
    if (isManuallySelectedCaseStudy && manualCaseStudies?.length > 0) {
      // Filter through the wordpress case studies and return all nodes that have a matching WP id
      const filteredCaseStudies = []
      // eslint-disable-next-line no-unused-expressions
      allWordpressWpCaseStudy?.nodes?.map(wpCaseStudy =>
        manualCaseStudies.forEach(manualCaseStudy => {
          if (wpCaseStudy.wordpress_id === manualCaseStudy.wordpress_id) {
            filteredCaseStudies.push(wpCaseStudy)
          }
        })
      )
      setCaseStudies(filteredCaseStudies)
    }
  }, [manualCaseStudies, allWordpressWpCaseStudy])

  return (
    <section className="recent-case-studies">
      <div className="wrapper">
        <h2 className="recent-case-studies-heading">
          {decodeEntities(heading)}
        </h2>
        {caseStudies.length > 0 && (
          <div className="recent-case-studies-slider" ref={slider}>
            <Carousel>
              {caseStudies.map(caseStudy => {
                const { title, content, link, featuredImage } = caseStudy
                return (
                  <div
                    className="recent-case-study"
                    onDragStart={handleOnDragStart}
                    key={title}
                  >
                    <div
                      className="recent-case-study-text"
                      style={{ height: maxSlideHeight }}
                    >
                      <span className="recent-case-study-small-title">
                        CASE STUDY
                      </span>
                      <h3 className="recent-case-study-title">
                        {decodeEntities(title)}
                      </h3>
                      <div
                        className="recent-case-study-excerpt"
                        dangerouslySetInnerHTML={{
                          __html: getExcerpt(content, 200),
                        }}
                      />
                      <Link to={decodeEntities(link)} className="button">
                        Find out more
                      </Link>
                    </div>
                    <div className="recent-case-study-image">
                      <div className="inner">
                        <Image image={featuredImage} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </Carousel>
          </div>
        )}
      </div>
    </section>
  )
}
