export const getIconSrc = icon => {
  switch (icon) {
    case 'aerospace':
      return '/icons/aerospace.svg'
    case 'commercial':
      return '/icons/commercial.svg'
    case 'compliance':
      return '/icons/compliance.svg'
    case 'custom':
      return '/icons/custom.svg'
    case 'defence':
      return '/icons/defence.svg'
    case 'flame':
      return '/icons/flame.svg'
    case 'mining':
      return '/icons/mining.svg'
    case 'oilrig':
      return '/icons/oilrig.svg'
    case 'rail':
      return '/icons/rail.svg'
    case 'time':
      return '/icons/time.svg'
    case 'turnkey':
      return '/icons/turnkey.svg'
    case 'qualified':
      return '/icons/qualified.svg'
    case 'staff':
      return '/icons/staff.svg'
    case 'trade':
      return '/icons/trade.svg'
    case 'marine':
      return '/icons/marine.svg'
    default:
      return null
  }
}
