import React from 'react';
import GatsbyLink from '../../components/GatsbyLink';
import { decodeEntities } from '../../utils/htmlParse';
import './ListBlock.scss';

export const ListBlock = (props) => {
  const {
    heading,
    list,
    content
  } = props;
  return (
    <section className="list-block">
      <div className="wrapper">
        <h2 className="list-block-heading">
          {decodeEntities(heading)}
        </h2>
        <div className="list-container">
          <ul>
            {list.map(item => {
              return (
                item.listItemLink?.url ? (
                  <li key={item.listItem}>
                    <GatsbyLink to={item.listItemLink?.url}>{item.listItem}</GatsbyLink>
                  </li>
                ) : <li key={item.listItem}>{item.listItem}</li>
              )
            })}
          </ul>
        </div>
        {content && (
          <div
            className="list-block-content"
            dangerouslySetInnerHTML={{__html: content}}
          />
        )}
      </div>
    </section>
  );
};
