import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from './Image';
import { decodeEntities } from '../utils/htmlParse';
import { isClient } from '../utils/helpers';
import { Carousel } from './Carousel';
import './RelatedItemsBlock.scss';

const RelatedItemsBlock = ({
  items,
  viewMoreText = 'View all items',
  viewMoreLink = null,
  heading = 'Related Items'
}) => {
  const [sliderActive, setSliderActive] = useState(false);
  const { site: { siteMetadata: { blogSlug } } } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          blogSlug
        }
      }
    }
  `);
  const handleResize = () => {
    if (window.innerWidth >= 1080) {
      setSliderActive(false);
    } else {
      setSliderActive(true);
    }
  }
  useEffect(() => {
    if (isClient) {
      window.addEventListener('resize', handleResize);
      setSliderActive(window.innerWidth < 1080);
    }
    return () => {
      if (isClient) window.removeEventListener('resize', handleResize);
    };
  });
  const ItemsWrapper = sliderActive ? Carousel : React.Fragment;
  const sliderSettings = sliderActive ? {
    buttonsDisabled: true,
    responsive: {
      0: {
        items: 1,
      },
      750: {
        items: 2
      },
    },
  } : null;
  return (
    <section className="related-items">
      <div className="wrapper">
        <h2 className="related-items-heading">{decodeEntities(heading)}</h2>
        <div className="related-items-wrapper">
          <ItemsWrapper {...sliderSettings}>
            {items.map(item => {
              const {
                link: relatedItemLink,
                title: relatedItemTitle,
                excerpt,
                featuredImage,
                services = null,
              } = item;
              const parentServices = services && services.filter(el => el.wordpress_parent === 0);
              const subServices = services && services.filter(el => el.wordpress_parent !== 0);
              return (
                <Link className="related-item" to={relatedItemLink} key={relatedItemLink}>
                  <div className="related-item-image">
                    <Image image={featuredImage} />
                  </div>
                  <div className="related-item-text">
                    {services && (
                      <div className="services">
                        <span className="parent-services">
                          {decodeEntities(parentServices.map(s=>s.name).join(', '))}
                        </span>
                        <span className="sub-services">
                          {decodeEntities(subServices.map(s=>s.name).join(', '))}
                        </span>
                      </div>
                    )}
                    <h3 className="related-item-title">
                      {decodeEntities(relatedItemTitle)}
                    </h3>
                    <div
                      className="related-item-excerpt"
                      dangerouslySetInnerHTML={{__html: excerpt}}
                    />
                  </div>
                </Link>
              );
            })}
          </ItemsWrapper>
        </div>
        <div className="view-all-items">
          <Link to={viewMoreLink || `/${blogSlug}/`}>
            {decodeEntities(viewMoreText)}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RelatedItemsBlock;
