import React from 'react';
import OnVisible from 'react-on-visible';
import { Carousel } from '../../components/Carousel';
import Image from '../../components/Image';
import Breadcrumbs from '../../components/Breadcrumbs';
import AcfLink from '../../components/AcfLink';
import './HomepageBanner.scss';
import HomePageImageQuery from '../../components/Image/HomePageImageQuery'

export const HomepageBanner = (props) => {
  const {
    smallHeading,
    heading,
    content,
    images,
    buttonLink,
    pageContext = {},
    location,
    pageTitle,
    customCrumbs = null,
    disableBreadcrumbs = false,
    previewing
  } = props;
  const { breadcrumb = {} } = pageContext;
  const { crumbs = null } = breadcrumb;
  return (
    <>
      <OnVisible wrappingElement="section" className="homepage-banner">
        <div className="wrapper">
          <div className="banner-text">
            <span
              className="banner-small-heading"
              dangerouslySetInnerHTML={{__html: smallHeading}}
            />
            <h1
              className="banner-heading"
              dangerouslySetInnerHTML={{__html: heading}}
            />
            <p className="banner-content">{content}</p>
            {buttonLink && <AcfLink className="button" {...buttonLink} />}
          </div>
        </div>
        <div className="banner-image">
          <Carousel
            buttonsDisabled
            dotsDisabled
            autoPlay
            infinite
            autoPlayInterval={4500}
            duration={500}
          >
            {images.map(( image, index ) => (
              location.pathname === "/" && index === 0 ? <HomePageImageQuery key={index} className="home-background"/> : <Image key={index} image={image.image} />
            ))}
          </Carousel>
        </div>
      </OnVisible>
      {location && location.pathname !== '/' && !previewing && !disableBreadcrumbs && (
        <div className="breadcrumbs-container">
          <div className="wrapper">
            <Breadcrumbs
              customCrumbs={customCrumbs}
              crumbs={crumbs}
              crumbLabel={pageTitle}
            />
          </div>
        </div>
      )}
    </>
  );
}
