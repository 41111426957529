import React from 'react';
import './GenericContent.scss';

export const GenericContent = ({ content }) => {
  return (
    <section className="generic-content">
      <div className="wrapper">
        <div
          className="generic-content-content"
          dangerouslySetInnerHTML={{__html: content}}
        />
      </div>
    </section>
  );
};
