import React from 'react';
import OnVisible from 'react-on-visible';
import { decodeEntities } from '../../utils/htmlParse';
import SearchBar from '../../components/SearchBar';
import './ProductSearch.scss';

export const ProductSearch = (props) => {
  const { heading, placeholder = "Find what you're looking for" } = props;
  return (
    <OnVisible wrappingElement="section" className="product-search">
      <div className="wrapper">
        <span className="product-search-small-heading">Products</span>
        <h2 className="product-search-heading">
          {decodeEntities(heading)}
        </h2>
        <div className="product-search-search-area">
          <SearchBar
            navigateTo="/products/search/"
            placeholder={placeholder}
          />
        </div>
      </div>
    </OnVisible>
  );
}
