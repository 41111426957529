import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { Carousel } from '../../components/Carousel'
import Image from '../../components/Image'
import { isClient } from '../../utils/helpers'

import './CardLayout.scss'
import { decodeEntities } from '../../utils/htmlParse'

/**
 * Displays a card.
 * Accepts at most 3 cards from ACF.
 */
export const CardLayout = ({ card = [], heading = '' }) => {
  const [sliderActive, setSliderActive] = useState(false)
  const handleResize = () => {
    if (window.innerWidth >= 1080) {
      setSliderActive(false)
    } else {
      setSliderActive(true)
    }
  }
  useEffect(() => {
    if (isClient) {
      window.addEventListener('resize', handleResize)
      setSliderActive(window.innerWidth < 1080)
    }
    return () => {
      if (isClient) window.removeEventListener('resize', handleResize)
    }
  })
  const ItemsWrapper = sliderActive ? Carousel : React.Fragment
  const sliderSettings = sliderActive
    ? {
        buttonsDisabled: true,
        responsive: {
          0: {
            items: 1,
          },
          750: {
            items: 2,
          },
        },
      }
    : null
  return (
    <section className="card-layout">
      <div className="wrapper">
        <h2 className="card-layout__heading">{decodeEntities(heading)}</h2>
        {card?.length > 0 && (
          <div className="card-layout__inner">
            <ItemsWrapper {...sliderSettings}>
              {card?.map((cardItem, index) => (
                <Card key={`card-${index}`} {...cardItem} />
              ))}
            </ItemsWrapper>
          </div>
        )}
      </div>
    </section>
  )
}

const Card = ({ link = '', heading = '', textContent = '', image = {} }) => {
  return link ? (
    <Link to={link} className="card-layout__card--link card-layout__card">
      {image && (
        <div className="card-layout__card--image">
          <Image image={image} />
        </div>
      )}
      <div className="card-layout__card--text">
        {heading && <h3 className="card-layout__card--title">{heading}</h3>}
        {textContent && <p>{textContent}</p>}
      </div>
    </Link>
  ) : (
    <div className="card-layout__card">
      {image && (
        <div className="card-layout__card--image">
          <Image image={image} />
        </div>
      )}
      <div className="card-layout__card--text">
        {heading && <h3 className="card-layout__card--title">{heading}</h3>}
        {textContent && <p>{textContent}</p>}
      </div>
    </div>
  )
}
